import { styled } from 'stitches.config';
import SmallLabel from 'Atoms/Typography/SmallLabel/SmallLabel';
import { CSS } from '@stitches/react';
import { useMemo } from 'react';

type AttributeListType = {
  value: string | boolean | undefined;
};

type PropTypes = {
  attributeList: AttributeListType[];
  marginBottom?: number;
  css?: CSS;
};

function Attributes({ attributeList, marginBottom = 2, css }: PropTypes) {
  const filteredList = useMemo(() => {
    return attributeList.filter((attribute) => attribute.value);
  }, [attributeList]);

  return (
    <ProductAttributeList css={css}>
      {filteredList.map((attribute, index) => {
        return (
          <AttributeItem key={index}>
            <SmallLabel marginBottom={marginBottom}>
              {attribute.value}
            </SmallLabel>
            {filteredList.length !== index + 1 && (
              <Dot css={{ mb: marginBottom }}>&#8226;</Dot>
            )}
          </AttributeItem>
        );
      })}
    </ProductAttributeList>
  );
}

const ProductAttributeList = styled('ul', {
  listStyle: 'initial',
  color: '$textPrimary',
  display: 'flex',
  listStyleType: 'none',
  gap: '8px',
});

const AttributeItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const Dot = styled('span', {
  position: 'relative',
  '@mediaMinLarge': {
    top: '-1.5px',
  },
});

export default Attributes;
