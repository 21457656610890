import { useReducer, useContext, createContext } from 'react';
import variantReducer, {
  VariantStateType,
  VariantActionType,
} from '../../Pages/ProductPage/Reducers/variantReducer';

type PropType = {
  children: React.ReactNode;
};

type ContextType = {
  state: VariantStateType;
  variantDispatch: React.Dispatch<VariantActionType>;
};

const VariantContext = createContext<ContextType>({} as ContextType);

export const VariantProvider = ({ children }: PropType) => {
  const [state, variantDispatch] = useReducer(variantReducer, {
    selectedVariant: null,
    hasColors: false,
    hasSizes: false,
    hasSelectedSize: false,
    errorSize: false,
  });

  return (
    <VariantContext.Provider value={{ state, variantDispatch }}>
      {children}
    </VariantContext.Provider>
  );
};

export const useVariantData = (): ContextType => {
  return useContext(VariantContext);
};
