import { styled } from 'stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

type PropType = {
  friendlyMonthlyPrice: string | undefined;
};

function PricePerMonth({ friendlyMonthlyPrice }: PropType) {
  const { productLabels } = useTranslationData();
  const { perMonth } = productLabels;

  return (
    <PricePerMonthContainer>
      {friendlyMonthlyPrice}
      {perMonth}
    </PricePerMonthContainer>
  );
}

export default PricePerMonth;

const PricePerMonthContainer = styled('div', {
  fontFamily: '$fontSecondary400',
  lineHeight: '$lh133',
  color: '$primaryLight3',
  letterSpacing: '$ls0',
  fs: 6,
  mb: 1,
});
