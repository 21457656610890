import { styled } from 'stitches.config';
import { InfoIcon } from 'Atoms/Icons';

type DeliveryStatusType = {
  label: string;
  color?: 'secondary' | 'transparent';
};

function DeliveryStatus({ label, color = 'secondary' }: DeliveryStatusType) {
  const iconColor = color === 'secondary' ? 'secondaryDark' : 'secondary';
  return (
    <DeliveryStatusContainer color={color}>
      <InfoIcon space={true} size={'s'} color={iconColor} />
      <Label color={color}>{label}</Label>
    </DeliveryStatusContainer>
  );
}

const DeliveryStatusContainer = styled('div', {
  py: 2,
  px: 4,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  variants: {
    color: {
      transparent: {
        backgroundColor: 'transparent',
        border: '1px solid $primary2',
      },
      secondary: {
        backgroundColor: '$secondary4',
      },
    },
  },
  defaultVariants: {
    color: 'secondary',
  },
});

const Label = styled('span', {
  fs: 6,
  fontFamily: '$fontSecondary500',
  variants: {
    color: {
      transparent: {
        color: '$textPrimary',
      },
      secondary: {
        color: '$textSecondary',
      },
    },
  },
  defaultVariants: {
    color: 'secondary',
  },
});

export default DeliveryStatus;
