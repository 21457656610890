import { styled } from 'stitches.config';

import ScrollContainer from 'react-indiana-drag-scroll';

import ImageModel from 'Models/Assets/ImageModel.interface';
import ProductImage from 'Atoms/Image/ProductImage';

type CarouselChevronType = {
  goToSlide: (index: number) => void;
  images: ImageModel[];
  currentImage: number;
  isUsed: boolean;
};

export const CarouselThumbnails = ({
  images,
  currentImage,
  goToSlide,
  isUsed,
}: CarouselChevronType) => {
  return (
    <ThumbnailContainer>
      {images?.map((image, index) => (
        <Thumbnail
          key={index}
          onClick={() => goToSlide(index)}
          active={currentImage === index}
        >
          <ProductImage
            src={
              isUsed
                ? `${image.src}?preset=product-page-mobile-secondary-used`
                : `${image.src}?preset=product-page-mobile-secondary`
            }
            isUsed={isUsed}
            alt={image.alt || ''}
          />
        </Thumbnail>
      ))}
    </ThumbnailContainer>
  );
};

const ThumbnailContainer = styled(ScrollContainer, {
  display: 'flex',
  justifyContent: 'start',
  mt: 2,
  mx: 4,
  pb: 4,
});

const Thumbnail = styled('button', {
  position: 'relative',
  h: '$thumbnailImageHeight',
  width: 58,
  backgroundColor: 'transparent',
  ml: 2,
  flexShrink: 0,
  '&:first-of-type': {
    ml: 0,
  },
  variants: {
    active: {
      true: {
        '&:after': {
          content: '',
          width: 'inherit',
          border: '2px solid $colors$JE68GreenPrimary',
          display: 'block',
          position: 'absolute',
          left: 0,
          mt: 2,
        },
      },
    },
  },
});
