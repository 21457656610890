import { styled } from 'stitches.config';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import Modal from 'Organisms/Modal/Modal';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import Input from 'Atoms/Input/Input';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { accountFormValidation } from 'Shared/Common/AccountFormValidation';
import Button from 'Atoms/Buttons/Button';
import { useEffect, useRef, useState } from 'react';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import TestDriveModel from 'Models/Email/TestDriveModel.interface';
import ProductPageModel from 'Models/Pages/ProductPage/ProductPageModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import Attributes from 'Molecules/Attributes/Attributes';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';

export type PropType = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
};

const API_URL = '/Email/Testdrive';

function TestDriveModal({ showModal, setShowModal }: PropType) {
  const { languageRoute } = useAppSettingsData();
  const { brand, manufacturerYear, displayName } =
    useCurrentPage<ProductPageModel>();

  const {
    user: {
      email: savedEmail,
      fullName: savedFullName,
      phoneNumber: savedPhoneNumber,
    },
  } = useUserStateData();

  const [formName, setName] = useState<string>(savedFullName ?? '');
  const [formPhoneNumber, setPhoneNumber] = useState<string>(
    savedPhoneNumber ?? ''
  );
  const [formEmail] = useState<string>(savedEmail ?? '');
  const [validFormData, setValidFormData] = useState<boolean>(false);

  const {
    productLabels: {
      bookTestDrive,
      sendRequest,
      loginBookTestDrive,
      howToBookTestDrive,
    },
    checkoutLabels: { phoneNumber: phoneNumberLabel },
    accountLabels: { email, signIn },
    validationLabels: { invalidEmail, invalidPhoneNumber, isRequired },
    commonLabels: {
      fullName,
      thanksForYourMessage,
      messageSent,
      contactDetails,
      somethingWentWrong,
    },
  } = useTranslationData();

  const {
    user: { authenticated },
    accountDispatch,
  } = useUserStateData();

  const body: TestDriveModel = {
    fullName: formName,
    phone: formPhoneNumber,
    email: formEmail,
    language: languageRoute,
    brand: brand,
    model: displayName,
  };

  const timerRef = useRef<NodeJS.Timeout>();

  const [status, setStatus] = useState<
    'init' | 'loading' | 'error' | 'success'
  >('init');

  useEffect(() => {
    if (showModal) setStatus((s) => (s === 'success' ? 'init' : s));
  }, [showModal]);

  useEffect(() => {
    if (status !== 'success') return;
    timerRef.current = setTimeout(() => {
      setName(savedFullName ?? '');
      setPhoneNumber(savedPhoneNumber ?? '');
      setValidFormData(false);
      setShowModal(false);
    }, 3000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [
    status,
    setName,
    setPhoneNumber,
    setValidFormData,
    setShowModal,
    savedFullName,
    savedPhoneNumber,
  ]);

  async function onSubmit(e?: { preventDefault?: () => void }): Promise<void> {
    if (e?.preventDefault) e?.preventDefault();

    if (!validFormData) return;

    try {
      setStatus('loading');
      const obj = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }).then((res) => res.json());

      setStatus(obj?.success ? 'success' : 'error');
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  }

  const onInputsValid = () => {
    setValidFormData(true);
  };

  const onInputsInvalid = () => {
    setValidFormData(false);
  };

  const onLogin = () => {
    setShowModal(false);
    accountDispatch({ type: 'toggle' });
  };

  if (status === 'success') {
    return (
      <Modal showModal={showModal} toggle={() => setShowModal(false)}>
        <H2 css={{ mb: 12 }}>{messageSent}</H2>
        <BulletPoint text={thanksForYourMessage} backgroundColor="primary" />
      </Modal>
    );
  }

  return (
    <Modal showModal={showModal} toggle={() => setShowModal(!showModal)}>
      <H2 css={{ mb: 4 }}>{bookTestDrive}</H2>
      <Attributes
        attributeList={[{ value: brand }, { value: manufacturerYear }]}
        marginBottom={1}
      />
      <ProductDisplayName>{displayName}</ProductDisplayName>
      <Paragraph css={HowToTestDrive}>{howToBookTestDrive}</Paragraph>

      {!authenticated && (
        <Login>
          <Paragraph>{loginBookTestDrive}</Paragraph>
          <Button onClick={onLogin} fullWidth>
            {signIn}
          </Button>
        </Login>
      )}

      <ContactForm onSubmit={onSubmit}>
        <FormTitle>{contactDetails}</FormTitle>
        <InputValidation
          onInputsValid={onInputsValid}
          onInputsInvalid={onInputsInvalid}
        >
          <Input
            onChange={setName}
            title={fullName}
            placeholder={fullName}
            validation={{
              required: true,
              errorMessage: isRequired.replace('{0}', fullName),
            }}
            disabled={!authenticated}
            autoComplete="given-name"
            name="Given Name"
            defaultValue={savedFullName ?? ''}
          />
          <Input
            onChange={setPhoneNumber}
            title={phoneNumberLabel}
            placeholder={phoneNumberLabel}
            validation={{
              required: true,
              errorMessage: invalidPhoneNumber,
              ...accountFormValidation.phoneNumber,
            }}
            disabled={!authenticated}
            autoComplete="tel"
            name="PhoneNumber"
            defaultValue={savedPhoneNumber ?? ''}
          />
          <Input
            title={email}
            placeholder={email}
            validation={{
              ...accountFormValidation.email,
              errorMessage: invalidEmail,
            }}
            disabled={true}
            autoComplete="email"
            name="Email"
            defaultValue={savedEmail}
          />
        </InputValidation>

        <Button fullWidth disabled={!authenticated} css={{ mt: 7 }}>
          {sendRequest}
        </Button>
      </ContactForm>

      {status === 'error' && somethingWentWrong}
    </Modal>
  );
}

const ProductDisplayName = styled('h1', {
  fs: 10,
  lineHeight: '$lh12',
  fontFamily: '$fontSecondary600',
  mb: 6,
  mt: 0,
  textTransform: 'uppercase',
  color: '$textPrimary',
});

const HowToTestDrive = {
  mb: 12,
};

const Login = styled('div', {
  mb: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

const ContactForm = styled('form', { mb: 7 });

const FormTitle = styled('span', {
  fs: 9,
  fontFamily: '$fontSecondary400',
  lineHeight: '$lh155',
  mb: 3,
  display: 'block',
});

export default TestDriveModal;
