import { useState } from 'react';

import { styled } from 'stitches.config';

type BlurredImageType = {
  index: number;
  lowQualitySrc: string;
  highQualitySrc: string;
  handleFullScreen: (index: number) => void;
  isFullWidth?: boolean;
  isUsed: boolean;
};

const BlurredImage = ({
  index,
  lowQualitySrc,
  highQualitySrc,
  handleFullScreen,
  isFullWidth,
  isUsed,
}: BlurredImageType) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const contrastStyle = isUsed ? 'none' : '$colors$imageFilter';
  const filterStyle = isLoading ? 'blur(10px)' : contrastStyle;
  const transitionStyle = isLoading ? 'none' : 'filter 0.3s ease-out';

  return (
    <Image
      alt={''}
      src={isLoading ? lowQualitySrc : highQualitySrc}
      onLoad={() => setIsLoading(false)}
      onClick={() => {
        handleFullScreen(index);
      }}
      css={{
        filter: filterStyle,
        transition: transitionStyle,
      }}
      isFullWidth={isFullWidth}
    />
  );
};

const Image = styled('img', {
  backgroundColor: '$backgroundSecondary',
  h: 'auto',
  w: '100%',
  maxH: '430px',
  pr: 0,
  cursor: 'pointer',
  objectFit: 'cover',
  flexBasis: '49%',
  width: '49%',
  flexGrow: 1,
  variants: {
    isFullWidth: {
      true: {
        width: '100%',
        flexBasis: '100%',
      },
    },
  },
});

export default BlurredImage;
