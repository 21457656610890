import CloseButton from 'Atoms/Buttons/CloseButton';
import Overlay from 'Atoms/Overlay/Overlay';
import React, { useRef, useEffect } from 'react';
import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';

type PropType = {
  children: React.ReactNode;
  showModal: boolean;
  close: () => void;
};

function FullScreenModal({ showModal, children, close }: PropType) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [close]);

  return (
    <ModalContainer isVisible={showModal}>
      <ModalContent ref={modalRef}>
        <CloseWrapper>
          <CloseButton
            size="l"
            css={{ marginLeft: 'auto' }}
            onClick={close}
            bgColor="secondary"
          />
        </CloseWrapper>
        {children}
      </ModalContent>
      <Overlay isVisible={showModal} />
    </ModalContainer>
  );
}

const CloseWrapper = styled('div', {
  display: 'flex',
  position: 'absolute',
  top: 16,
  right: 16,
  zIndex: 9,
});

const ModalContainer = styled('div', {
  position: 'fixed',
  zIndex: -1,
  visibility: 'hidden',
  top: 0,
  right: 0,
  h: '100%',
  w: '100%',
  opacity: 0,
  transition: `all ${timings.oneHalf} ${animation.timingFn}`,
  variants: {
    isVisible: {
      true: {
        opacity: 1,
        visibility: 'initial',
        zIndex: '$zIndices$Modal',
      },
    },
  },
});

const ModalContent = styled('div', {
  width: '100%',
  height: '100vh',
  opacity: 1,
  margin: '0 auto',
  backgroundColor: '$modalContentBackground',
  zIndex: '$zIndices$Modal',
  position: 'relative',
});

export default FullScreenModal;
