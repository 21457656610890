import KexLink from 'Kex/KexLink';

import ProductImage from 'Atoms/Image/ProductImage';
import SimpleProductRelation from 'Models/Pages/ProductPage/SimpleProductRelation.interface';

import { styled } from 'stitches.config';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

type PropTypes = {
  packageItem: SimpleProductRelation;
};

function PackageCardItem({ packageItem }: PropTypes) {
  const { image, name, productUrl } = packageItem;
  const { missingImage } = useAppSettingsData();

  return (
    <CartItemContainer>
      <KexLink css={{ mb: 0 }} href={productUrl} underlined={false}>
        <ImageContainer>
          <ProductImage
            src={
              image
                ? `${image.src}?preset=small-product-card-desktop`
                : `${missingImage.src}?preset=small-product-card-desktop`
            }
            alt={image?.alt}
          />
        </ImageContainer>
      </KexLink>
      <Details>
        <TopWrapper>
          <StyledKexLink href={productUrl} underlined={false}>
            <ProductCardTitle title={name} />
          </StyledKexLink>
        </TopWrapper>
      </Details>
    </CartItemContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',
});

const CartItemContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  mb: 4,
});

const ImageContainer = styled('div', {
  width: 96,
  height: 72,
  mr: 4,
  '@mediaMinLarge': {
    width: 104,
    height: 80,
  },
});

const TopWrapper = styled('div', {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});
const Details = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export default PackageCardItem;
