import ImageModel from 'Models/Assets/ImageModel.interface';
import { useEffect, useRef } from 'react';
import { styled } from 'stitches.config';
import {
  CarouselChevronPrevious,
  CarouselChevronNext,
} from './CarouselChevrons';
import NukaCarousel from 'nuka-carousel';
import { CarouselThumbnails } from './CarouselThumbnails';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useMedia from 'Shared/Hooks/useMedia';

type ProductImages = {
  images: ImageModel[];
  handleFullScreen: (index: number) => void;
  isFullScreen?: boolean;
  hasCounter?: boolean;
  hasThumbnail?: boolean;
  currentImage: number;
  handleCurrentImage: (current: number) => void;
  isUsed: boolean;
  isMCC?: boolean;
};

function Carousel({
  images,
  handleFullScreen,
  isFullScreen,
  hasThumbnail,
  currentImage,
  handleCurrentImage,
  isUsed,
  hasCounter,
  isMCC = false,
}: ProductImages) {
  const carouselRef = useRef<HTMLInputElement>(null);
  const isTablet = useMedia(mediaQueryTypes.mediaMinSmall);
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  useEffect(() => {
    carouselRef?.current?.focus();
  }, []);

  const getImagePresets = (image: ImageModel) => {
    const fullScreenImage = isFullScreen
      ? `${image.src}?preset=product-page-full-screen`
      : '';

    const mobileImage = isMCC
      ? `${image.src}?preset=configuration-page-mobile-main`
      : isUsed
      ? `${image.src}?preset=product-page-mobile-main-used`
      : `${image.src}?preset=product-page-mobile-main`;

    const tabletImage = isMCC
      ? `${image.src}?preset=configuration-page-tablet-main`
      : isUsed
      ? `${image.src}?preset=product-page-tablet-main-used`
      : `${image.src}?preset=product-page-tablet-main`;

    const desktopImage = isMCC
      ? `${image.src}?preset=configuration-page-desktop-main`
      : isUsed
      ? `${image.src}?preset=product-page-desktop-main-used`
      : `${image.src}?preset=product-page-desktop-main`;

    const nonFullScreenImage = isTablet
      ? isDesktop
        ? desktopImage
        : tabletImage
      : mobileImage;

    return isFullScreen ? fullScreenImage : nonFullScreenImage;
  };

  return (
    <CarouselWrapper>
      <NukaCarousel
        innerRef={carouselRef}
        enableKeyboardControls={true}
        slideIndex={currentImage}
        afterSlide={(slideIndex) => handleCurrentImage(slideIndex)}
        renderBottomCenterControls={({ currentSlide, goToSlide }) => {
          if (hasThumbnail) {
            return (
              <CarouselThumbnails
                images={images}
                currentImage={currentImage}
                goToSlide={goToSlide}
                isUsed={isUsed}
              />
            );
          }
          if (hasCounter) {
            return (
              <CarouselStepsCount>
                {currentSlide + 1} / {images.length}
              </CarouselStepsCount>
            );
          }
        }}
        renderCenterLeftControls={({ previousSlide }) => (
          <CarouselChevronPrevious
            onclick={previousSlide}
            currentImage={currentImage}
            hasThumbnail={!!hasThumbnail}
          />
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <CarouselChevronNext
            onclick={nextSlide}
            imagesLength={images.length}
            currentImage={currentImage}
            hasThumbnail={!!hasThumbnail}
          />
        )}
      >
        {images.map((image, index) => {
          return (
            <CarouselImage
              key={index}
              src={getImagePresets(image)}
              alt={image?.alt ?? ''}
              loading="lazy"
              onClick={() => {
                const isNotInFullScreen = !isFullScreen;

                isNotInFullScreen && handleFullScreen(index);
              }}
              isFullScreen={isFullScreen}
              isUsed={isUsed}
            />
          );
        })}
      </NukaCarousel>
    </CarouselWrapper>
  );
}

const CarouselImage = styled('img', {
  backgroundColor: '$carouselImageBackground',
  width: '100%',
  objectFit: 'contain',
  '&:hover': {
    cursor: 'pointer',
  },

  variants: {
    isFullScreen: {
      true: {
        h: '100vh',
        objectFit: 'contain',
      },
    },
    isUsed: {
      false: {
        filter: '$colors$imageFilter',
      },
      true: {
        backgroundColor: 'unset',
      },
    },
  },
});

const CarouselWrapper = styled('div', {
  position: 'relative',
  '@mediaMinLarge': {
    mx: 0,
  },
  // override default nuka carousel css - to position our thumbnail
  '.slider-control-bottomcenter': {
    bottom: 'unset !important',
    position: 'relative !important',
    width: '100vw',
  },
  '& section': {
    outline: 'transparent',
  },
});

const CarouselStepsCount = styled('span', {
  position: 'absolute',
  bottom: 48,
  color: '$textSecondary',
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  ls: 'ls1',
  lineHeight: 'lh16',
  fs: 12,
  margin: '0 auto',
  left: 0,
  right: 0,
  width: 'fit-content',
  whiteSpace: 'nowrap',
});

export default Carousel;
