import { styled } from 'stitches.config';
import { ChevronIcon } from 'Atoms/Icons';

type CarouselChevronType = {
  onclick: () => void;
  imagesLength?: number;
  currentImage: number;
  hasThumbnail: boolean;
};

export const CarouselChevronNext = ({
  onclick,
  imagesLength,
  currentImage,
  hasThumbnail,
}: CarouselChevronType) => {
  const hasNextSlide = imagesLength && currentImage < imagesLength - 1;

  if (!hasNextSlide) return <></>;

  return (
    <NextCircle onClick={onclick} hasThumbnail={hasThumbnail}>
      <ChevronIcon
        css={{ transform: 'rotate(-90deg)' }}
        color="secondaryDark"
        size="s"
      />
    </NextCircle>
  );
};

export const CarouselChevronPrevious = ({
  onclick,
  currentImage,
  hasThumbnail,
}: CarouselChevronType) => {
  const hasPreviousSlide = currentImage !== 0;

  if (!hasPreviousSlide) return <></>;

  return (
    <PreviousCircle onClick={onclick} hasThumbnail={hasThumbnail}>
      <ChevronIcon
        css={{ transform: 'rotate(90deg)' }}
        color="secondaryDark"
        size="s"
      />
    </PreviousCircle>
  );
};

const CircleBaseStyle = {
  backgroundColor: '$pureWhite',
  borderRadius: '50%',
  position: 'relative',
  height: '32px',
  width: '32px',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    hasThumbnail: {
      true: { position: 'absolute', bottom: 24 },
    },
  },
};

const PreviousCircle = styled('button', {
  ...CircleBaseStyle,
  left: 16,
});

const NextCircle = styled('button', {
  ...CircleBaseStyle,
  right: 16,
});
