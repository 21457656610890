import { styled } from 'stitches.config';
import { H2, H4 } from 'Atoms/Typography/Headings/Heading';
import SmallLabel from 'Atoms/Typography/SmallLabel/SmallLabel';
import Modal from 'Organisms/Modal/Modal';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import Input from 'Atoms/Input/Input';
import TextArea from 'Atoms/Input/TextArea';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { accountFormValidation } from 'Shared/Common/AccountFormValidation';
import Button from 'Atoms/Buttons/Button';
import { useEffect, useRef, useState } from 'react';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import ContactUsModel from 'Models/Email/ContactUsModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import ProductPageModel from 'Models/Pages/ProductPage/ProductPageModel.interface';

export type PropType = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
};

const API_URL = '/Email/Contactus';

function ContactModal({ showModal, setShowModal }: PropType) {
  const timerRef = useRef<NodeJS.Timeout>();

  const {
    productLabels: { contactUs },
    checkoutLabels: { phoneNumber: phoneNumberLabel },
    accountLabels: { email },
    validationLabels: { invalidEmail, invalidPhoneNumber },
    commonLabels: {
      phoneOpen: phoneOpenLabel,
      phoneOpenHours,
      phoneNumber,
      fullName,
      message,
      thanksForYourMessage,
      sendMessage,
      messageSent,
      writeToUs,
      somethingWentWrong,
    },
  } = useTranslationData();
  const { languageRoute } = useAppSettingsData();
  const {
    user: {
      email: savedEmail,
      fullName: savedFullName,
      phoneNumber: savedPhoneNumber,
    },
  } = useUserStateData();
  const { brand, displayName } = useCurrentPage<ProductPageModel>();

  const [formName, setName] = useState<string>(savedFullName ?? '');
  const [formPhoneNumber, setPhoneNumber] = useState<string>(
    savedPhoneNumber ?? ''
  );
  const [formEmail, setEmail] = useState<string>(savedEmail ?? '');
  const [formMessage, setMessage] = useState<string>('');
  const [validFormData, setValidFormData] = useState<boolean>(false);

  const [status, setStatus] = useState<
    'init' | 'loading' | 'error' | 'success'
  >('init');

  useEffect(() => {
    if (showModal) setStatus((s) => (s === 'success' ? 'init' : s));
  }, [showModal]);

  const body: ContactUsModel = {
    brand: brand,
    model: displayName,
    fullName: formName,
    phoneNumber: formPhoneNumber,
    email: formEmail,
    text: formMessage,
    language: languageRoute,
  };

  async function submitForm(e?: {
    preventDefault?: () => void;
  }): Promise<void> {
    if (e?.preventDefault) e?.preventDefault();

    if (!validFormData) return;

    try {
      setStatus('loading');
      const obj = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }).then((res) => res.json());

      setStatus(obj?.success ? 'success' : 'error');
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  }

  const onInputsValid = () => {
    setValidFormData(true);
  };

  const onInputsInvalid = () => {
    setValidFormData(false);
  };

  useEffect(() => {
    if (status !== 'success') return;

    timerRef.current = setTimeout(() => {
      setName(savedFullName ?? '');
      setPhoneNumber(savedPhoneNumber ?? '');
      setEmail(savedEmail ?? '');
      setMessage('');
      setValidFormData(false);
      setShowModal(false);
    }, 3000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [
    status,
    setName,
    setPhoneNumber,
    setEmail,
    setMessage,
    setValidFormData,
    setShowModal,
    savedFullName,
    savedPhoneNumber,
    savedEmail,
  ]);

  if (status === 'success') {
    return (
      <Modal showModal={showModal} toggle={() => setShowModal(false)}>
        <H2 css={{ mb: 12 }}>{messageSent}</H2>
        <BulletPoint text={thanksForYourMessage} backgroundColor="primary" />
      </Modal>
    );
  }

  return (
    <Modal showModal={showModal} toggle={() => setShowModal(!showModal)}>
      <H2 css={{ mb: 12 }}>{contactUs}</H2>
      <ContactInfo>
        <ContactInfoColumn>
          <SmallLabel marginBottom={1}>{phoneNumberLabel}</SmallLabel>
          <span>{phoneNumber}</span>
        </ContactInfoColumn>
        <ContactInfoColumn>
          <SmallLabel marginBottom={1}>{phoneOpenLabel}</SmallLabel>
          <span>{phoneOpenHours}</span>
        </ContactInfoColumn>
      </ContactInfo>
      <ContactForm onSubmit={submitForm}>
        <H4 css={{ mb: 4 }}> {writeToUs}</H4>
        <InputValidation
          onInputsValid={onInputsValid}
          onInputsInvalid={onInputsInvalid}
        >
          <Input
            onChange={setName}
            title={fullName}
            placeholder={fullName}
            validation={{ required: true, errorMessage: fullName }}
            autoComplete="given-name"
            name="Given Name"
            defaultValue={savedFullName ?? ''}
          />
          <Input
            onChange={setPhoneNumber}
            title={phoneNumberLabel}
            placeholder={phoneNumberLabel}
            validation={{
              required: true,
              errorMessage: invalidPhoneNumber,
              ...accountFormValidation.phoneNumber,
            }}
            autoComplete="tel"
            name="PhoneNumber"
            defaultValue={savedPhoneNumber ?? ''}
          />
          <Input
            onChange={setEmail}
            title={email}
            placeholder={email}
            validation={{
              ...accountFormValidation.email,
              errorMessage: invalidEmail,
            }}
            autoComplete="email"
            name="Email"
            defaultValue={savedEmail}
          />
          <TextArea
            onChange={setMessage}
            title={message}
            placeholder={message}
            name="Message"
          />
        </InputValidation>

        <Button
          fullWidth
          css={{ mt: 7 }}
          disabled={!validFormData}
          onClick={submitForm}
        >
          {sendMessage}
        </Button>
      </ContactForm>

      {status === 'error' && somethingWentWrong}
    </Modal>
  );
}

const ContactInfo = styled('div', {
  display: 'flex',
  gap: 96,
  mb: 16,
});

const ContactInfoColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ContactForm = styled('form', { mb: 7 });

export default ContactModal;
