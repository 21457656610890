import { styled } from 'stitches.config';
import DeliveryStatus from 'Atoms/DeliveryStatus/DeliveryStatus';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import ProductTranslations from '../../Models/Translations/ProductTranslations.interface';

type StockViewerType = {
  productLabels: ProductTranslations;
  isUsed: boolean;
};

function StockViewer({ productLabels, isUsed }: StockViewerType) {
  const { inStock, notInStock, salesStart, extendedDeliveryTime } =
    productLabels;

  const { state } = useVariantData();

  const isHideStock = (!state.hasSelectedSize && state.hasSizes) || isUsed;

  if (!state.selectedVariant || isHideStock) {
    return <StockContainer></StockContainer>;
  }

  const { stock } = state.selectedVariant;

  const salesStartString = salesStart?.replace(
    '{0}',
    stock?.salesStartFormatted
  );

  return (
    <StockContainer>
      {stock?.stockAvailableAtVendor ? (
        <DeliveryStatus label={extendedDeliveryTime}></DeliveryStatus>
      ) : stock?.salesStartFormatted ? (
        <DeliveryStatus
          label={salesStartString}
          color={'transparent'}
        ></DeliveryStatus>
      ) : (
        <Stock>
          <Indicator inStock={stock?.inStock} />
          <Paragraph size={'xs'}>
            {stock?.inStock ? (
              <>
                {stock?.stockQuantity} {inStock}
              </>
            ) : (
              notInStock
            )}
          </Paragraph>
        </Stock>
      )}
    </StockContainer>
  );
}

const StockContainer = styled('div', {
  mb: '28px',
  '@mediaMinLarge': {
    mb: '32px',
  },
});

export const Stock = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  color: '$textPrimary',
});

export const Indicator = styled('span', {
  display: 'inline-block',
  borderRadius: '50%',
  background: '$JE68GreenPrimary',
  w: 2,
  h: 2,
  mr: 2,
  variants: {
    inStock: {
      true: {
        backgroundColor: '$JE68GreenPrimary',
      },
      false: {
        backgroundColor: 'red',
      },
    },
  },
});

export default StockViewer;
