import SimpleProductRelation from 'Models/Pages/ProductPage/SimpleProductRelation.interface';
import PackageCardItem from 'Organisms/ProductCard/PackageCard';
import { H4 } from 'Atoms/Typography/Headings/Heading';
import { isEmpty } from 'Shared/Common/Helpers';

type ProductPackageType = {
  packageItems: SimpleProductRelation[];
  isPackage: boolean;
  packageIncludes: string;
};

function ProductPackage({
  packageItems,
  isPackage,
  packageIncludes,
}: ProductPackageType) {
  if (!isPackage || isEmpty(packageItems)) return <></>;

  return (
    <>
      <H4 css={{ mb: 1 }}>{packageIncludes}</H4>
      {!isEmpty(packageItems) &&
        packageItems
          .filter((packageItem) => packageItem)
          .map((packageItem) => {
            return (
              <PackageCardItem
                key={packageItem.code}
                packageItem={packageItem}
              />
            );
          })}
    </>
  );
}

export default ProductPackage;
